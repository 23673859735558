import React, { Component } from "react"
import { Map, Marker, GoogleApiWrapper } from "google-maps-react"

const containerStyle = {
  margin: "auto",
  position: "relative",
  filter: "drop-shadow(10px 10px 10px #6d6d6d)",
}

class Maps extends Component {
  render() {
    return (
      <div className="App">
        <Map
          google={this.props.google}
          containerStyle={containerStyle}
          initialCenter={{ lat: 22.32208531969363, lng: 114.20785621152012 }}
          zoom={15}
        >
          <Marker
            title={"開心按揭服務有限公司"}
            name={"開心按揭服務有限公司"}
            position={{ lat: 22.32185, lng: 114.20785621152012 }}
            onClick={() =>
              window.open(
                "https://www.google.com.hk/maps/dir//''/@22.3220225,114.172846,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x0:0x9d0032dec05eee62!2m2!1d114.2078656!2d22.3219469!3e0",
                "_blank"
              )
            }
          />
        </Map>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBP3_QjQEArSK4c8H8MFgJTMLa3YA_tJ4Q",
})(Maps)
