import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Button from "../components/button"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Map from "../components/map"
import "./style.scss"

import company from "../images/company.png"
import service1 from "../images/service1.png"
import service2 from "../images/service2.png"
import service3 from "../images/service3.png"
import service4 from "../images/service4.png"
import service5 from "../images/service5.png"

export default function Home() {
  return (
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>主頁 | 開心按揭服務有限公司</title>
      </Helmet>
      <Navbar />
      <div className="content">
        <section id="hero">
          <div id="hero_content">
            <h1>
              以客為本 <br />
              以誠為先
            </h1>
            <Button
              text="即時申請"
              bg_color="#ffffff"
              text_color="#4d4d4d"
              url="/application"
            />
          </div>
        </section>
        <section id="main_company">
          <div id="company_container">
            <div
              data-sal="slide-right"
              data-sal-duration="1000"
              data-sal-easing="ease"
              id="company_left"
            >
              <img src={company} alt="Happy Mortgage Services Limited client" />
            </div>
            <div
              data-sal="fade"
              data-sal-duration="1000"
              data-sal-easing="ease"
              id="company_right"
            >
              <div id="main_title">
                <h3>開心按揭服務有限公司</h3>
              </div>
              <p>
                開心按揭服務有限公司是根據香港放債人條例 (香港法例163章)
                ，正式註冊成立之認可放債人機構。自創立至今，開心為客戶提供多元化又具靈活的物業貸款服務。開心是一間「以客為本、以誠為先」的放債人機構，一直以專業、專誠、專注為客戶提供更多選擇的資金周轉平台。
                <br />
                <br />
                我們概擁有比銀行更簡單、更快捷的批核手續，客戶只須提供簡單資料，9分鐘即可獲得初步批核，翌日即可提取現金。
              </p>
            </div>
          </div>
        </section>
        <section id="main_service">
          <div id="service_container">
            <div id="service_title">
              <h3>貸款服務</h3>
            </div>
            <div id="service_content">
              <div className="service_row">
                <div
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="service_box"
                >
                  <div className="service_box_image">
                    <img src={service1} alt="物業一按" />
                  </div>
                  <div className="service_box_content">
                    <h4>物業一按</h4>
                    <p>
                      開心按揭服務有限公司為客户提供物業一按貸款，物業貸款額高達物業估值九成。
                    </p>
                    <AnchorLink to="/service#service_1">了解更多</AnchorLink>
                  </div>
                </div>
                <div
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="service_box"
                >
                  <div className="service_box_image">
                    <img src={service2} alt="物業二按" />
                  </div>
                  <div className="service_box_content">
                    <h4>物業二按</h4>
                    <p>
                      開心按揭服務有限公司為客户提供物業二按套現無上限之貸款服務，毋需抵押樓契，不論樓齡。
                    </p>
                    <AnchorLink to="/service#service_2">了解更多</AnchorLink>
                  </div>
                </div>
              </div>
              <div className="service_row">
                <div
                  className="service_empty"
                  style={{ backgroundColor: "#89CFF2" }}
                />
                <div
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="service_box"
                >
                  <div className="service_box_image">
                    <img src={service3} alt="單邊按揭" />
                  </div>
                  <div className="service_box_content">
                    <h4>單邊按揭</h4>
                    <p>聯名業權一人可借</p>
                    <AnchorLink to="/service#service_4">了解更多</AnchorLink>
                  </div>
                </div>
                <div
                  className="service_empty"
                  style={{ backgroundColor: "#FBEB9A" }}
                />
              </div>
              <div className="service_row">
                <div
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="service_box"
                >
                  <div className="service_box_image">
                    <img src={service4} alt="建期貸款、丁屋發展" />
                  </div>
                  <div className="service_box_content">
                    <h4>建期貸款、丁屋發展</h4>
                    <p>
                      若閣下正拓展村屋，本公司樂意提供發展村屋建築費及補地價之融資服務。
                    </p>
                    <AnchorLink to="/service#service_5">了解更多</AnchorLink>
                  </div>
                </div>
                <div
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="service_box"
                >
                  <div className="service_box_image">
                    <img src={service5} alt="居屋業主貸款" />
                  </div>
                  <div className="service_box_content">
                    <h4>居屋業主貸款</h4>
                    <p>
                      開心按揭服務有限公司為客户提供免補地價之居屋業主私人貸款，物業貸款額高達一佰萬或以上。
                    </p>
                    <AnchorLink to="/service#service_3">了解更多</AnchorLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          data-sal="fade"
          data-sal-duration="1000"
          data-sal-easing="ease"
          id="main_application"
        >
          <div id="application_container">
            <div id="application_title">
              <h3>申請辦法</h3>
            </div>
            <div id="application_content">
              <div className="application_row">
                <Button
                  text="網上表格"
                  bg_color="#84B9FF"
                  text_color="#ffffff"
                  url="/application#form"
                />
                <Button
                  text="電話查詢"
                  bg_color="#84B9FF"
                  text_color="#ffffff"
                  url="/application#phone"
                />
              </div>
              <div className="application_row">
                <Button
                  text="短信查詢"
                  bg_color="#84B9FF"
                  text_color="#ffffff"
                  url="/application#message"
                />
                <Button
                  text="親臨本行"
                  bg_color="#84B9FF"
                  text_color="#ffffff"
                  url="/application#visit"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="main_contact">
          <div id="contact_container">
            <div id="contact_title">
              <h3 className="purpleHighlight">
                忠告：借錢梗要還，咪俾錢中介。
              </h3>
              <h4 className="purpleHighlight" style={{ paddingBottom: "50px" }}>
                查詢及投訴熱線: 2640 6789
              </h4>
              <hr style={{ width: "80%" }} />
              <h3 style={{ paddingTop: "50px" }}>聯絡我們</h3>
            </div>
            <div id="contact_content">
              <div
                id="contact_left"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <h3>開心按揭服務有限公司</h3>
                <p>放債人牌照號碼: 1334/2022</p>
              </div>
              <div
                id="contact_center"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <div className="contact_sub_left">
                  <p>24小時申請熱線:&nbsp;</p>
                  <p>客戶服務熱線:&nbsp;</p>
                  <p>傳真:&nbsp;</p>
                  <p>短訊或 WhatsApp查詢:&nbsp;</p>
                </div>
                <div className="contact_sub_right">
                  <p>2640 6789</p>
                  <p>2640 6789</p>
                  <p>2644 6789</p>
                  <p>5489 9534</p>
                </div>
              </div>
              <div
                id="contact_right"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-easing="ease"
              >
                <div className="contact_sub_left">
                  <p>電郵:&nbsp;</p>
                  <p>地址:&nbsp;</p>
                  <p>辦公時間:&nbsp;</p>
                  <p>網址:&nbsp;</p>
                </div>
                <div className="contact_sub_right">
                  <p>query@happymsl.com.hk</p>
                  <p>九龍灣常悅道9號企業廣場一期二座11樓1109C室</p>
                  <p>10:30 am – 6:30 pm(星期一至五)</p>
                  <p>www.happymsl.com.hk</p>
                </div>
              </div>
            </div>
            <div id="contact_map">
              <Map />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  )
}
