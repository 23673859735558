import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import "./button.scss"

import { Icon } from "@iconify/react"
import fileAlt from "@iconify/icons-fa-solid/file-alt"
import phoneIcon from "@iconify/icons-fa-solid/phone"
import commentsIcon from "@iconify/icons-fa-regular/comments"
import mapMarkerAlt from "@iconify/icons-fa-solid/map-marker-alt"

function ButtonIcon(props) {
  if (props.text === "網上表格") {
    return <Icon icon={fileAlt} className="button_icon" />
  } else if (props.text === "電話查詢") {
    return <Icon icon={phoneIcon} className="button_icon" />
  } else if (props.text === "短信查詢") {
    return <Icon icon={commentsIcon} className="button_icon" />
  } else if (props.text === "親臨本行") {
    return <Icon icon={mapMarkerAlt} className="button_icon" />
  } else {
    return <span />
  }
}

export default function Button(props) {
  const buttonStyle = {
    backgroundColor: props.bg_color,
    color: props.text_color,
  }
  return (
    <div className="button_wrapper">
      <AnchorLink to={props.url}>
        <div className="button" style={buttonStyle}>
          <ButtonIcon text={props.text} />
          {props.text}
        </div>
      </AnchorLink>
    </div>
  )
}
